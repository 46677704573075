(function() { 'use strict';
  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);
  window.$HEADER = $('.header');

  window.IS_MOBILE = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) !== null;
  window.IS_TOUCH_DEVICE = 'ontouchend' in document || !!navigator.msMaxTouchPoints || !!navigator.maxTouchPoints;



  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  Inputmask.extendAliases({
    'phone-mask': {
      regex: '(\\+7|8) \\([0-689]\\d{2}\\) \\d{3}(\\-\\d{2}){2}',
      placeholder: '+7 (___) ___-__-__',
      keepStatic: true
    }
  });

  // Phone
  $('input[type="tel"], input[autocomplete="tel"]').inputmask('phone-mask');


  $DOCUMENT
    // Scroll to
    // ---------
    .on('click.js-scroll-to', '.js-scroll-to', function(e) {
      e.preventDefault();

      var $lnk = $(this),
          $elemToScroll = $($lnk.attr('href').substring($lnk.attr('href').lastIndexOf('#'))),
          speed = $lnk.data('scrollSpeed') || 150,
          offset = $lnk.data('scrollOffset') || 0;

      $WINDOW.scrollTo($elemToScroll, {duration: speed, offset: offset});
    })

    // Callback content row show
    // -------------------------
    .on('click.callback-content-link', '.callback-form .form-row-content-link span', function(e) {
      e.preventDefault();

      $(this).parents('.callback-form').addClass('callback-form-with-content');
    })

    // Faq list answers visible toggle
    // -------------------------------
    .on('click.faqListAnswer', '.faq-list > div > div > div:first-child', function(e) {
      e.preventDefault();

      var $self = $(this).parent();

      if (!$self.hasClass('active'))
        $self.addClass('active').children('div + div').slideDown(200);
      else
        $self.removeClass('active').children('div + div').slideUp(200);
    });


  // Header service menu
  // -------------------
  if( window.IS_TOUCH_DEVICE )
    $DOCUMENT
      .on('touchstart', function() {
        $('.header-service-menu-container > ul > li > ul > li').removeClass('is-hovered');
      })
      .on('touchstart.header-service-menu-span', '.header-service-menu-container > ul > li > ul > li', function() {
        $(this).closest('li').addClass('is-hovered').siblings().removeClass('is-hovered');
      });


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    loop: true,
    margin: 0,

    infobar: false,
    buttons: [
      'close',
      false,
      false,
      false
    ],

    smallBtn: false,

    protect : true,

    focusElement: '.js-autofocus-inp'
  });

  $('[data-fancybox]').fancybox();


  // Slick sliders
  // -------------
  var slickSettings = {
    autoplay: true,
    autoplaySpeed: 6000,
    prevArrow: '<div class="slick-prev"></div>',
    nextArrow: '<div class="slick-next"></div>',
    customPaging: function() { return $('<div />'); },
    draggable: false
  };

  // Main rotator
  $('.main-rotator-container').slick($.extend({}, slickSettings, {
    centerMode: true,
    centerPadding: '0px',
    fade: true,
    dots: true,
    slide: '.main-rotator-slide-container',
    appendArrows: '#main-rotator-controls > div',
    appendDots: '#main-rotator-dots > div > div'
  }));

  // Main gallery rotator
  $('.main-gallery-rotator').each(function() {
    var $self = $(this);

    $self
      /*.on('beforeChange', function(e, slick) {
        slick.$slider.find('.slick-slide').removeClass('slick-quick').filter('.slick-active').next().addClass('slick-quick');
      })*/
      .slick($.extend({}, slickSettings, {
        dots: true,
        slide: '.main-gallery-image-container',
        prevArrow: '<div class="slick-prev"><div class="button-style"><div></div></div></div>',
        nextArrow: '<div class="slick-next"><div class="button-style"><div></div></div></div>',
        appendArrows: $self.find('.main-gallery-controls'),
        appendDots: $self.find('.main-gallery-dots > div > div')
      }));
  });


  // Portfolio filter
  // ----------------
  if( $('.portfolio-page-block-container').length ) {
    var $portfolioGrid = $('.portfolio-page-block-container .portfolio-items').isotope({
          itemSelector: '.list-view-item',
          layoutMode: 'fitRows',
          percentPosition: true,
          transitionDuration: '0.5s',
          containerStyle: null
        }),
        $links = $('.portfolio-page-block-container .quick-link-list a');

    $links.on('click.portfolio-filter', function(e) {
      e.preventDefault();

      location.hash = encodeURIComponent( $(this).data('filterValue') );
    });

    $WINDOW.on('hashchange', function(e) {
      var filterValue = decodeURIComponent( location.hash.slice(1) );

      if( filterValue.length ) {
        $links.removeClass('active').filter('[data-filter-value="' + filterValue + '"]').addClass('active');

        if( filterValue === 'all' ) {
          $portfolioGrid.isotope({ filter: '*'});
        } else {
          $portfolioGrid.isotope({ filter: '[data-filter-section="' + filterValue  + '"]'});
        }
      }
    });
  }


  // Callbacks
  // ---------
  /*CALLBACKS['reachGoalPhoneOrder'] = function() {
    if( typeof yaCounter46020042 === 'object' )
      yaCounter46020042.reachGoal('phone_order');
  };*/

})();